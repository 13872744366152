/* .buy-tickets-container {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f8f8f8;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
} */

.buy-tickets-title {
  text-align: center;
  color: #e62b1e;
  margin-bottom: 20px;
}

.tickets-available {
  text-align: center;
  font-weight: bold;
  margin-bottom: 20px;
  color: #e62b1e;
}

.buy-tickets-form {
  display: flex;
  flex-direction: column;
}

.form-group {
  margin-bottom: 20px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

.form-input,
.form-select {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 16px;
}

.form-select {
  appearance: none;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='%23333' viewBox='0 0 12 12'%3E%3Cpath d='M6 9L1 4h10L6 9z'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right 10px center;
}

.submit-button {
  background-color: #e62b1e;
  color: white;
  border: none;
  padding: 12px 20px;
  font-size: 18px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.submit-button:hover {
  background-color: #c62218;
}

.submit-button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.error-message {
  color: #e62b1e;
  margin-bottom: 20px;
  text-align: center;
}

.dialog-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.dialog {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  max-width: 80%;
  width: 300px;
  text-align: center;
}

.dialog p {
  margin-bottom: 20px;
  font-size: 16px;
  color: #333;
}

.dialog button {
  background-color: #e62b1e;
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.dialog button:hover {
  background-color: #c62218;
}

@media (max-width: 768px) {
  .buy-tickets-container {
    padding: 10px;
  }
}

.IMP-Note {
  text-align: center;
  font-weight: bold;
  margin-bottom: 20px;
  color: #090909;
}



/* Add these new styles at the top of your existing CSS file */
.warning-banner {
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #ff4d4d;
  color: white;
  padding: 15px 0;
  z-index: 1000;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.warning-content {
  max-width: 800px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 0 20px;
}

.warning-icon {
  font-size: 24px;
}

.warning-text {
  font-size: 18px;
  font-weight: 700;
  text-align: center;
  margin: 0;
  letter-spacing: 0.5px;
}

/* Your existing CSS remains the same */
.buy-tickets-container {
  max-width: 1200px;
  margin: 20px auto;
  padding: 20px;
  background-color: #f8f8f8;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-wrap: wrap; /* Ensures columns wrap on smaller screens */
  gap: 20px;
}

.buy-tickets-form-container {
  flex: 3;
  min-width: 300px; /* Ensure the form doesn't shrink too much */
}

.buy-tickets-card-container {
  flex: 1;
  min-width: 300px; /* Ensure the card doesn't shrink too much */
  background-color: #ffffff;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 20px;
  text-align: center;
}

/* Add responsiveness for smaller screens */
@media screen and (max-width: 768px) {
  .buy-tickets-container {
    flex-direction: column; /* Stack the form and card vertically */
  }

  .buy-tickets-form-container,
  .buy-tickets-card-container {
    flex: unset; /* Reset flex property for single-column layout */
    min-width: unset;
    width: 100%; /* Ensure both take full width */
  }
}

.card-title {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 10px;
  color: #e62b1e;
}

.card-content {
  font-size: 16px;
  color: #333;
  margin-bottom: 10px;
}

.card-button {
  background-color: #e62b1e;
  color: white;
  border: none;
  padding: 10px 15px;
  font-size: 16px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.card-button:hover {
  background-color: #c62218;
}
@media screen and (max-width: 768px) {
  .buy-tickets-form-container {
    margin-bottom: 20px;
  }
}