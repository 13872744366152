/* TED Info Page Styles */

.ted-info-page {
  background-color: black;
  color: white;
  padding: 2rem;
  margin-top: 5vh;
}

.ted-title {
  color: #ff2b06;
  font-size: 2.25rem;
  font-weight: bold;
  margin-bottom: 1.5rem;
}

.ted-content {
  display: flex;
}

.ted-text {
  width: 66.666667%;
  padding-right: 2rem;
}

.ted-paragraph {
  margin-bottom: 1rem;
}

/* ... existing styles ... */

.ted-button {
  background-color: #e62b1e;
  color: white;
  border: none;
  padding: 12px 24px;
  font-size: 16px;
  font-weight: bold;
  text-transform: uppercase;
  cursor: pointer;
  transition: background-color 0.3s ease;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.ted-button:hover {
  background-color: #c62218;
}

.ted-button:active {
  background-color: #a61d14;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  transform: translateY(1px);
}

.ted-image-container {
  width: auto;
}

.ted-image {
  margin-bottom: 1rem;
  width: 100%;
}

@media (max-width:1000px) {
  .ted-content {
    display: flex;
    flex-direction: column-reverse;
  }
  .ted-image {
    margin-bottom: 1rem;
    margin-inline: auto;
    width: 55%;
  }
  .ted-text{
    width: auto;
  }
}

.tedx-title {
  color: #ff2b06;
  font-size: 2.25rem;
  font-weight: bold;
  margin-top: 3rem;
  margin-bottom: 1.5rem;
}

.tedx-content {
  display: flex;
}

.tedx-image-container {
  width: 33.333333%;
}

.tedx-text {
  width: 66.666667%;
  padding-left: 2rem;
}