/* css for loader in case if we need */
/* .loader-container {
  --uib-size: 100px;
  --uib-color: white;
  --uib-speed: 1.25s;
  --uib-cube-size: calc(var(--uib-size) * 0.2);
  --uib-arc-1: -90deg;
  --uib-arc-2: 90deg;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background-color: black;
  z-index: 9999;
}


.loader {
  --cube-container-height: calc(var(--uib-size) * 0.8);
  display: flex;
  align-items: center;
  width: var(--uib-cube-size);
  height: var(--cube-container-height);
  transform-origin: center bottom;
  transform: rotate(var(var(--uib-arc-1)));
  animation: metronome var(--uib-speed) linear infinite;

  &::after {
    content: '';
    display: block;
    width: var(--uib-cube-size);
    height: var(--uib-cube-size);
    background-color: var(--uib-color);
    border-radius: 25%;
    animation: morph var(--uib-speed) linear infinite;
    transform-origin: center left;
    transition: background-color 0.3s ease;
  }
}

@keyframes metronome {
  0% {
    transform: rotate(var(--uib-arc-1));
  }

  50% {
    transform: rotate(var(--uib-arc-2));
  }

  50.001% {
    transform: translateX(var(--cube-container-height)) rotate(var(--uib-arc-1));
  }

  100% {
    transform: rotate(var(--uib-arc-1));
  }
}

@keyframes morph {
  0% {
    transform: scaleX(0.65) scaleY(1.3);
  }

  15% {
    transform: scaleX(1.5);
  }

  30%,
  50.001% {
    transform: scaleX(1);
  }

  55% {
    transform: scaleX(0.8) scaleY(1.2);
    animation-timing-function: ease-in;
  }

  65%,
  80% {
    transform: scaleX(1);
  }

  90%,
  100% {
    transform: scaleX(0.65) scaleY(1.3);
  }
} */

.App {
  padding: 0;
}


/* Navbar styles */
.navbar_nav {
  background: #000000;
  display: flex;
  justify-content: space-between;
  padding: 0 20px;
  position: -webkit-sticky;
  position: sticky;
  /* position: fixed; */
  top: 0;
  z-index: 100000;
}

.navbar_left {
  align-items: center;
  display: flex;
  z-index: 100000;
  /* padding-top: 0.5em; */
}

.navbar_logo1__mHDkT {
  width: 220px;
  padding: -1em 0 -1em 0;
}

.navbar_right__5z6Ih {
  align-items: center;
  display: flex;
  position: relative;
}

.navbar_lin__yrOtl {
  color: #ffffff;
  font-family: Inter, sans-serif;
  font-size: 13px;
  font-weight: 700;
  padding: 20px 15px;
  position: relative;
  text-decoration: none;
}

.navbar_lin__yrOtl::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: 8px;
  /* Adjust as needed */
  width: 0;
  height: 4px;
  /* Adjust as needed */
  background-color: #eb0028;
  /* Underline color */
  transition: width 0.3s ease;
}

.navbar_lin__yrOtl:hover::after {
  width: 100%;
}


.navbar_head__Un2yU {
  display: flex;
  flex-direction: column;
}

.navbar_lin1__bGONE {
  color: #000000;
  font-family: Inter, sans-serif;
  font-size: .75rem;
  font-weight: 700;
  padding: 15px 20px;
  text-decoration: none;
}


.navbar_lin__yrOtl:hover {
  color: #eb0028;
}


/* Hide certain elements on smaller screens */
@media only screen and (min-width: 1100px) {
  .navbar_logo1__mHDkT {
    order: -1;
  }

  .navbar_left {
    margin-right: auto;
  }
}

/* Show/hide hamburger menu */
@media only screen and (max-width: 1100px) {
  .navbar_left {
    order: 1;
  }
}

/* Hamburger menu button styles */
.navbar_toggle__1Pu10 {
  padding: 0;
  width: 40px;
  height: 40px;
  background: none;
  border: none;
  cursor: pointer;
}

.navbar_toggle__1Pu10 svg {
  stroke: currentColor;
  fill: currentColor;
  stroke-width: 0;
  height: 2em;
  width: 2em;
}

.navbar_sidehead__HVrOC {
  animation: navbar_slidetoright__I15Vs .2s ease-out;
  background: #fff;
  height: 100vh;
  left: 0;
  margin-top: 15px;
  padding: 20px;
  position: fixed;
  top: 40px;
  width: 250px;
  z-index: -1;
}

navbar_slidetoright__I15Vs {
  transform: translateX(-100%);
}

.navbar_ll__nKuuS {
  font-size: 29px;
  padding: 9px;
}

/*scroll button*/
.scrollButton {
  align-items: center;
  border-radius: 50%;
  bottom: 20px;
  box-shadow: 0 0 10px #0003;
  cursor: pointer;
  display: flex;
  height: 60px;
  justify-content: center;
  position: fixed;
  right: 20px;
  width: 60px;
  z-index: 2;
}

#scrollButton_progressValue__8B7zn {
  align-items: center;
  background-color: #fff;
  border-radius: 50%;
  display: block;
  display: flex;
  height: 50px;
  justify-content: center;
  width: 50px;
}

#scrollButton_progressValue__8B7zn svg {
  animation: scrollButton_gototop__r7d09 1.2s linear infinite alternate-reverse;
}

.scrollButton_progressWrap__eg57C {
  align-items: center;
  border-radius: 50%;
  bottom: 20px;
  box-shadow: 0 0 10px #0003;
  cursor: pointer;
  display: flex;
  height: 60px;
  justify-content: center;
  position: fixed;
  right: 20px;
  width: 60px;
}

@keyframes scrollButton_gototop__r7d09 {
  0% {
    transform: translateY(-2px);
  }

  100% {
    transform: translateY(5px);
  }
}

/* footer */
.footerStyles_footer__Ro537 {
  background: #000;
  background-size: 595px auto;
  padding: 32px 32px 0;
}


@media screen and (min-width: 768px) {
  .footerStyles_footer_main__QPqPA {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto auto;
  }
}


@media screen and (min-width: 768px) {
  .footerStyles_footer_main__QPqPA {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto auto;
  }
}

.footerStyles_footer_main__QPqPA {
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-template-rows: auto;
}

.footerStyles_footer_social__ncNPp {
  padding-top: 32px;
}

.footerStyles_footer_social__ncNPp {
  padding-bottom: 20px;
}

.footerStyles_social_icon__BHDZ9 {
  border-radius: 50%;
  color: #fff;
  /* Initial color */
  display: inline-block;
  font-size: 21px;
  height: 32px;
  line-height: 34px;
  margin-right: 16px;
  text-align: center;
  text-decoration: none;
  width: 32px;
  transition: color 0.3s ease, background-color 0.3s ease;
  /* Smooth transition */
}

.footerStyles_social_icon__BHDZ9 svg {
  transition: fill 0.3s ease, color 0.3s ease;
}

.footerStyles_social_icon__BHDZ9:hover svg {
  fill: #eb0028;
  /* Change the icon fill color on hover */
}

svg {
  color: white;
  stroke: currentcolor;
  fill: currentcolor;
  stroke-width: 0;
  height: 1em;
  width: 1em;
}

.footerStyles_email__yM-\+x {
  color: #fff;
  padding-top: 10px;
}

.footerStyles_gmail__fYYwp {
  color: #eb0028;
  cursor: pointer;
}

.footerStyles_footer_col_2__2aZ3f {
  display: block;
  margin: 0;
}

.footerStyles_quick_links {
  color: #eb0028;
  font-size: 18px;
  font-weight: 600;
  line-height: 21px;
  padding-bottom: 15px;
  text-transform: none;
  padding-left: 30px;

}

.footerStyles_links_col_2__Qv075 {
  color: #fff;
  display: block;
  font-size: 15px;
  margin-bottom: 0px;
  text-decoration: none;
  padding-left: 30px;
  transition: color 0.3s ease, text-decoration 0.3s ease;
  /* Smooth transition */

}

.footerStyles_links_col_2__Qv075:hover {
  color: #eb0028;
  /* Change color on hover */

}

.footerStyles_footer_col_3__y6BD4 {
  color: #828282;
  font-size: 13px;
  line-height: 21px;
}

.footerStyles_footer_col_3_h6__l9XUv {
  color: #eb0028;
  font-size: 15px;
  font-weight: 600;
  line-height: 21px;
  padding-bottom: 0px;
  text-transform: none;
}

.footerStyles_credits__Kg8YY {
  clear: both;
  color: #fff;
  font-size: 13px;
  padding: 30px 0 16px;
}

.footer-links {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  /* This adds space between the links */
  justify-content: center;
  /* Center the links */
  text-align: left;
  /* Ensure text is left-aligned */
}

.footer-links a {
  flex: 1 1 45%;
  /* Take up 45% of the width for each link */
  text-align: left;
  margin-bottom: 10px;
  /* Add some space between rows */
}


@media screen and (max-width: 767px) {
  .footerStyles_footer_main__QPqPA {
    grid-template-columns: 1fr;
    /* Change to single column layout */
  }

  .footerStyles_footer_col_2__2aZ3f {
    order: 1;
    /* Change the order of this element */
    margin-top: 1rem;
    /* Add some spacing */
  }
}

@media screen and (min-width: 768px) {
  .footerStyles_footer_main__QPqPA {
    grid-template-columns: 1fr 2fr;
    /* Restore original layout */
  }

  .footerStyles_footer_col_2__2aZ3f {
    margin-top: 0;
    /* Remove top margin */
    order: 2;
    /* Restore original order */
  }
}

.footer img {
  max-width: 100%;
  /* Set maximum width to 100% of the container */
  height: auto;
  /* Maintain aspect ratio */
}

/* .footerStyles_footer__Ro537 {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
} */


.coreteam {
  padding: 60px;
}

.meetteam {
  color: #eb0028;
}

.TeamMember_link__WlwN3 {
  color: #000;
}

.aboutusteam {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  margin-top: 3rem;
}


@media screen and (max-width: 890px) {
  .aboutusteam {
    display: block;

  }
}

a {
  text-decoration: none;
}

.TeamMember_imgBox__xMdN4 img {
  --color: #eb0028;
  --border: 10px;
  --offset: 20px;
  --gap: 5px;
  --_c: var(--color) var(--border), #0000 0 calc(100% - var(--border)), var(--color) 0;
  --_o: calc(var(--offset)* 3);
  background: linear-gradient(var(--_c)) var(--_o) var(--_o), linear-gradient(90deg, var(--_c)) var(--_o) var(--_o);
  background-repeat: no-repeat;
  background-size: calc(100% - var(--_o)) calc(100% - var(--_o));
  cursor: pointer;
  height: 100%;
  padding: calc(var(--gap) + var(--border)) calc(var(--gap) + var(--border) + var(--offset)) calc(var(--gap) + var(--border) + var(--offset)) calc(var(--gap) + var(--border));
  transform: scale(1);
  transition: .5s;
  width: 100%;
}

.TeamMember_imgBox__xMdN4 img:hover {
  --offset: 0px;
  margin-bottom: 20px;
}

.TeamMember_imgTitle__zaUW\+ {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-top: 2px;
}

.TeamMember_imgBox__xMdN4 {
  padding: 20px 50px;
}

/* carousel */
.aboutUs_outerbox__iGhTQ {

  padding: 2vh;
}

.carousel-root {
  outline: none;
  align-self: center;
}

/* added just in case needed. Out covering our carousel */
.outerCarousel {
  max-width: fit-content;
  padding-top: 2vh;

}

@media (min-width:1000px) {
  .outerCarousel {
    padding: 8vh;
  }
}

.aboutUs_innerbox__TJZTu {
  display: flex;
  justify-content: space-between;
}

.carousel.carousel-slider {
  margin-inline: auto;
  overflow: hidden;
  justify-content: center;
  align-self: center;
}

.carousel {
  position: relative;
  width: 100%;
}

.carousel.carousel-slider .control-arrow {
  bottom: 0;
  color: #fff;
  font-size: 26px;
  margin-top: 0;
  padding: 5px;
  top: 0;
}

.carousel.carousel-slider .control-arrow {
  bottom: 0;
  color: #fff;
  font-size: 26px;
  margin-top: 0;
  padding: 5px;
  top: 0;
}

.carousel .control-next.control-arrow {
  right: 0;
}

.carousel .control-arrow,
.carousel.carousel-slider .control-arrow {
  background: none;
  border: 0;
  cursor: pointer;
  filter: alpha(opacity=40);
  font-size: 32px;
  opacity: .4;
  position: absolute;
  top: 20px;
  transition: all .25s ease-in;
  z-index: 2;
}

.carousel .control-arrow {
  background: none;
  border: 0;
  font-size: 18px;
  margin-top: -13px;
  outline: 0;
  top: 50%;
}

.carousel .control-prev.control-arrow {
  left: 0;
}

.carousel .control-arrow,
.carousel.carousel-slider .control-arrow {
  background: none;
  border: 0;
  cursor: pointer;
  filter: alpha(opacity=40);
  font-size: 32px;
  opacity: .4;
  position: absolute;
  top: 20px;
  transition: all .25s ease-in;
  z-index: 2;
}

.carousel .control-arrow {
  background: none;
  border: 0;
  font-size: 18px;
  margin-top: -13px;
  outline: 0;
  top: 50%;
}

.carousel * {
  box-sizing: border-box;
}

.carousel .slider-wrapper {
  margin: auto;
  overflow: hidden;
  transition: height .15s ease-in;
  width: 100%;
}

.carousel .control-prev.control-arrow:before {
  border-right: 8px solid #fff;
}

.carousel .control-arrow:before,
.carousel.carousel-slider .control-arrow:before {
  border-bottom: 8px solid #0000;
  border-top: 8px solid #0000;
  content: "";
  display: inline-block;
  margin: 0 5px;
}

.carousel .slider-wrapper.axis-horizontal .slider {
  -ms-box-orient: horizontal;
  display: -moz-flex;
  display: flex;
}

.carousel .slider.animated {
  transition: all .35s ease-in-out;
}

.carousel .slider {
  list-style: none;
  margin: 0;
  padding: 0;
  position: relative;
  width: 100%;
}

.carousel .slider-wrapper.axis-horizontal .slider .slide {
  flex-direction: column;
  flex-flow: column;
}

.carousel .slide {
  margin: 0;
  min-width: 100%;
  position: relative;
  text-align: center;
}

.carousel-status {
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}

.leftarrow,
.rightarrow {
  width: 3%;
  height: 3%;
}

.leftarrow,
.rightarrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 30px;
  /* Adjust as needed */
  height: auto;
  cursor: pointer;
}

.leftarrow {
  left: 0;
}

.rightarrow {
  right: 0;
}

.carousel * {
  box-sizing: border-box;
}

.AboutCarousel_button__QZSvF {
  align-items: center;
  background-color: #fff;
  border: none;
  color: #eb0028;
  cursor: pointer;
  display: flex;
  font-size: 20px;
  font-weight: 700;
  height: 50px;
  justify-content: center;
  margin: 30px auto;
}


button {
  appearance: auto;

  text-rendering: auto;
  color: buttontext;
  letter-spacing: normal;
  word-spacing: normal;
  line-height: normal;
  text-transform: none;
  text-indent: 0px;
  text-shadow: none;
  display: inline-block;
  text-align: center;
  align-items: flex-start;
  cursor: default;
  box-sizing: border-box;
  background-color: buttonface;
  margin: 0em;
  padding-block: 1px;
  padding-inline: 6px;
  border-width: 2px;
  border-style: outset;
  border-color: buttonborder;
  border-image: initial;
}

.AboutCarousel_button__QZSvF {
  align-items: center;
  background-color: #fff;
  border: none;
  color: #eb0028;
  cursor: pointer;
  display: flex;
  font-size: 20px;
  font-weight: 700;
  height: 50px;
  justify-content: center;
  margin: 30px auto;
}

.carousel .slider.animated {
  transition: transform 0.5s ease;
  /* Adjust the duration and easing as needed */
}

.carousel .slide {
  margin: 0;
  min-width: 100%;
  position: relative;
  text-align: center;
  flex: 0 0 100%;
  /* Ensure each slide takes up the full width */
  opacity: 0;
  /* Initially hide all slides */
}

.carousel .slide.selected {
  opacity: 1;
  /* Show only the selected slide */
}

/* sponsors */
.partners_centering__mEmQ2 {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 2em;
  justify-content: center;
  margin: 20px;
  padding: 20px;
}

.partners_title__cWB8o {
  color: #e0e0e0;
  font-size: 38px;
  font-weight: 350;
}

.sponsors_para {
  color: #e0e0e0;
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}

.partners_content__XGtJa {
  color: #e0e0e0;
  font-size: 18px;
  font-weight: 300;
  text-align: center;
}

.partners_button__nkcDD {
  background-color: #eb0028;
  border: 2px solid #eb0028;
  border-radius: 5px;
  color: #fff;
  display: block;
  font-size: 18px;
  margin: 10px auto auto;
  padding: 10px 20px;
  text-decoration: none;
  transition: background-color .3s, color .3s;
}

.partners_button__nkcDD:hover {
  background-color: #fff;
  color: #eb0028;
}

/* 

[data-aos][data-aos][data-aos-delay="700"].aos-animate, body[data-aos-delay="700"] [data-aos].aos-animate {
  transition-delay: .7s;
}
[data-aos^=zoom][data-aos^=zoom].aos-animate {
  opacity: 1;
  transform: translateZ(0) scale(1);
}
[data-aos][data-aos][data-aos-easing=ease-in-back], body[data-aos-easing=ease-in-back] [data-aos] {
  transition-timing-function: cubic-bezier(.6,-.28,.735,.045);
}
[data-aos][data-aos][data-aos-duration="1500"], body[data-aos-duration="1500"] [data-aos] {
  transition-duration: 1.5s;
} */
.partners_title__cWB8o.aos-init.aos-animate {
  opacity: 0;
  transform: scale(0.6);
  transition: opacity 0.5s ease-out, transform 0.5s ease-out;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: scale(0.6);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}

[data-aos-delay="700"].aos-animate {
  animation: fadeIn 0.5s ease-out 0.7s forwards;
}

/* newsletter */
.newsletter_container__ZfLoB {
  background: #ededed;
  color: #000;
  display: flex;
  justify-content: center;
  padding: 63px 0;
}

.newsletter_newimg__ZpZHM {
  width: 330px;
}

.newsletter_right__mGdQ8 {
  display: flex;
  flex-direction: column;
  margin-left: 40px;
}

.newsletter_heading__UotNR {
  font-size: 35px;
  font-weight: 600;
  margin-bottom: 6px;
  padding: 16px 0 5px;
}

.newsletter_subheading__53QGB {
  font-weight: 200;
  padding-bottom: 10px;
}

.newsletter_form__dqxuO {
  align-items: start;
  display: flex;
}

.newsletter_form__dqxuO input {
  border: 2px solid #e0e0e0;
  border-radius: 4px;
  color: #000;
  font-size: 14px;
  padding: 9px 16px;
  width: 312px;
}

.newsletter_success__Y9srE {
  color: #eb0028;
  font-weight: 700;
  margin-left: 10px;
  margin-top: 5px;
  visibility: hidden;
  width: 312px;
}

.newsletter_btn__jJJ-P {
  background-color: #000;
  border: none;
  border-radius: 5px;
  color: #fff;
  font-size: 15px;
  font-weight: 500;
  letter-spacing: .3px;
  margin-left: 5px;
  max-width: 200px;
  padding: 12px;
}

@media only screen and (max-width: 830px) {
  .newsletter_container__ZfLoB {
    align-items: center;
    flex-direction: column;
    padding: 40px 20px;
  }

  .newsletter_form__dqxuO {
    align-items: center;
    flex-direction: column;
  }

  .newsletter_btn__jJJ-P {
    margin-left: 0;
    margin-top: 20px;
    width: 100%;
  }

  .newsletter_right__mGdQ8 {
    margin-left: 0;
    text-align: center;
    width: 100%;
  }

  /* .partners_title__cWB8o aos-init aos-animate{
  display: none;
} */
}



@media only screen and (max-width: 650px) {
  .newsletter_newimg__ZpZHM {
    width: 250px;
  }

  .newsletter_heading__UotNR {
    font-size: 30px;
  }

  .newsletter_subheading__53QGB {
    font-size: 15px;
  }
}

/* contactus */


.contactUs_main__K5-vK {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: auto auto 30px;
}

.contactUs_content__GojN3 {
  display: flex;
  flex-direction: column;
  margin-left: 5%;
  overflow: hidden;
  width: 50%;
}

.contactUs_formContainer__tPtU6 {
  margin-left: 8%;
  margin-right: 5%;
  margin-top: 2%;
  width: 50%;
}

.contactUs_image__HnRNf {
  height: 300px;
  margin: auto;
}

.contactUs_title__Kgi-W {
  color: #eb0028;
  font-size: 40px;
  font-weight: 700;
  margin: 0 0 5%;
}

.contactUs_para__kMKHs {
  font-size: 16px;
  font-weight: 400;
  line-height: 140%;
  margin: 60px 0;
}

.contactUs_form__\+xwV4 {
  display: flex;
  flex-direction: column;
  margin-top: 50px;
}

.contactUs_formField__cPIkI {
  background-color: #dadada;
  border: 0;
  border-radius: 0;
  display: block;
  font-size: 14px;
  margin-bottom: 20px;
  outline: none;
  padding: 16px;
  width: 100%;
}

.contactUs_comments__UC869 {
  padding-bottom: 40px;
}

.contactUs_checkboxContainer__jawZS {
  color: #e0e0e0;
  align-items: center;
  display: flex;
  font-size: 18px;
  justify-content: start;
  margin: 30px 0;
  text-align: start;
}

.contactUs_checkbox__ZFnuD {
  height: 18px;
  margin: 0 10px 0 0;
  width: 18px;
}

.contactUs_arrow__b6vOg {
  display: inline-block;
  font-size: 30px;
  margin: 0 0 5px 8px;
}

.contactUs_privacy__\+gCuK {
  font-size: 15px;
  font-weight: 300;
}

.contactUs_required__K3dRw {
  font-size: 14px;
  font-weight: 300;
  margin-top: 15px;
}

.Thanks {
  color: #eb0028;
  padding: 10px;
  padding-left: 0;
  font-size: large;
}


@media screen and (max-width: 999px) {
  .contactUs_title__Kgi-W {
    margin-bottom: 20px;
    margin-top: 5%;
  }

  .contactUs_para__kMKHs {
    margin-bottom: 50px;
    margin-top: 40px;
  }

  .contactUs_formContainer__tPtU6 {
    margin: 0;
    width: 80%;
  }
}



@media (max-width: 799px) {
  .contactUs_image__HnRNf {
    height: 300px;

  }

  .contactUs_content__GojN3 {
    width: 100%;
  }

  .contactUs_formContainer__tPtU6 {
    width: 75%;
    /* align-items: center; */
    padding-left: 11%;
  }

  .contactUs_main__K5-vK {
    display: block;
  }
}

/* sponsors extra */
.partners_title__cWB8o {
  color: #000;
  font-size: 38px;
  font-weight: 350;
}

.partners_content__XGtJa {
  color: #000;
  font-size: 18px;
  font-weight: 300;
  text-align: center;
}

.scrolled .partners_title__cWB8o img,
.scrolled .partners_title__cWB8o div {
  transform: translateY(-30px) scale(1.07);
  /* Apply transformation when scrolled */
}

@keyframes identifier {
  0% {

    [data-aos][data-aos][data-aos-easing=ease-in-back],
    body[data-aos-easing=ease-in-back] [data-aos] {
      transition-timing-function: cubic-bezier(.6, -.28, .735, .045);
    }

    [data-aos][data-aos][data-aos-duration="1500"],
    body[data-aos-duration="1500"] [data-aos] {
      transition-duration: 1.5s;
    }

    [data-aos][data-aos][data-aos-delay="700"],
    body[data-aos-delay="700"] [data-aos] {
      transition-delay: 0;
    }

    [data-aos][data-aos][data-aos-easing=ease],
    body[data-aos-easing=ease] [data-aos] {
      transition-timing-function: ease;
    }

    [data-aos][data-aos][data-aos-duration="400"],
    body[data-aos-duration="400"] [data-aos] {
      transition-duration: .4s;
    }

    [data-aos^=zoom][data-aos^=zoom] {
      opacity: 0;
      transition-property: opacity, transform;
    }

    [data-aos=zoom-out-up] {
      transform: translate3d(0, 100px, 0) scale(1.2);
    }
  }

  100% {

    [data-aos][data-aos][data-aos-delay="700"].aos-animate,
    body[data-aos-delay="700"] [data-aos].aos-animate {
      transition-delay: .7s;
    }

    [data-aos^=zoom][data-aos^=zoom].aos-animate {
      opacity: 1;
      transform: translateZ(0) scale(1);
    }

    [data-aos][data-aos][data-aos-easing=ease-in-back],
    body[data-aos-easing=ease-in-back] [data-aos] {
      transition-timing-function: cubic-bezier(.6, -.28, .735, .045);
    }

    [data-aos][data-aos][data-aos-duration="1500"],
    body[data-aos-duration="1500"] [data-aos] {
      transition-duration: 1.5s;
    }

    [data-aos][data-aos][data-aos-delay="700"],
    body[data-aos-delay="700"] [data-aos] {
      transition-delay: 0;
    }

    [data-aos][data-aos][data-aos-easing=ease],
    body[data-aos-easing=ease] [data-aos] {
      transition-timing-function: ease;
    }

    [data-aos][data-aos][data-aos-duration="400"],
    body[data-aos-duration="400"] [data-aos] {
      transition-duration: .4s;
    }

    [data-aos^=zoom][data-aos^=zoom] {
      opacity: 0;
      transition-property: opacity, transform;
    }

    [data-aos=zoom-out-up] {
      transform: translate3d(0, 100px, 0) scale(1.2);
    }
  }

}

/* .requiredstyle{
  display: grid;
  padding: 10px;
} */
/* .footerStyles_footer_col_2__2aZ3f {
  display: block;
  margin: 0;
} */
/* @media only screen and (min-width: 768px) {
  .footer {
    flex-direction: row;
    justify-content: space-between;
  }

  .footer .mainfooter {
    flex-direction: row;
    justify-content: space-between;
  }

  .footerStyles_footer_main__QPqPA {
    flex-direction: row;
    justify-content: space-between;
  }
} */
/* .footer img {
  max-width: 100%; /* Set maximum width to 100% of the container */
/* height: auto; Maintain aspect ratio */
/* } */


.team-box {
  display: flex;
  flex-direction: column;
  text-align: center;
}

/* .team-container {
  padding: 0.4rem;
} */

/* .team-section {
  margin-bottom: 3rem;
} */

.section-title {
  font-family: 'Michroma', sans-serif;
  font-size: 2rem;
  color: #eb0028;
  text-align: center;
  margin-bottom: 1rem;
}

.section-title::before,
.section-title::after {
  content: '--';
  flex: 1;
  border-bottom: 2px solid white;
  margin: 0 10px;
  min-width: 50px;
}

@media (max-width: 768px) {
  .section-title {
    font-size: 6vw;
    content: '----';
  }
}

@media (max-width: 480px) {
  .section-title {
    font-size: 7vw;
    content: '----';
  }
}


.team-member-grid {
  display: flex;
  justify-content: center;
  gap: 1.5rem;
  flex-wrap: wrap;
  margin-bottom: 3rem;
}

.card-border {
  position: relative;
  padding: 0.15rem;
  border-radius: 12px;
  background: none;
  display: flex;
  overflow: hidden;
  box-sizing: border-box;
  /* Ensure padding is included in width/height */
}

.card-border::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: inherit;
  /* Match the card's border-radius */
  background: conic-gradient(from 0deg,
      #FF073A,
      #FF0000,
      #FF5C5C,
      #FF1744,
      #F60000,
      #FF416C,
      #FF2400);
  z-index: -1;
  animation: rotateFill 8s linear infinite;
  box-sizing: border-box;
}

.team-member-card {
  background-color: #1c1c1e;
  color: white;
  padding: 1rem;
  border-radius: inherit;
  width: 275px;
  text-align: center;
  position: relative;
  z-index: 1;
  box-sizing: border-box;
}


@keyframes rotateFill {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.member-photo {
  width: 100%;
  border-radius: 50%;
  margin-bottom: 0.25rem;
}

.social-links a {
  margin: 0 0.5rem 0;
  color: white;
}

.our_team_head {
  margin-top: -2em;
  z-index: 3;
}

/* .socialImg a {
  width: 0.05rem;
  height: 0.05rem;
} */



/*Home page card CSS starts here*/
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;700;800&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}

/* Remove flex properties from body */
body {
  min-height: 100vh;
  background: #000000;
  display: block;
}

/* Centering cards within container */
body .container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  max-width: 1200px;
  margin: 40px auto;
}

body .container .card {
  position: relative;
  min-width: 320px;
  height: 440px;
  box-shadow: inset 5px 5px 5px rgba(0, 0, 0, 0.2),
    inset -5px -5px 15px rgba(255, 255, 255, 0.1),
    5px 5px 15px rgba(0, 0, 0, 0.3), -5px -5px 15px rgba(255, 255, 255, 0.1);
  border-radius: 15px;
  margin: 30px;
  transition: 0.5s;
}



body .container .card .box {
  position: absolute;
  top: 20px;
  left: 20px;
  right: 20px;
  bottom: 20px;
  background: #2a2b2f;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  transition: 0.5s;
}

body .container .card .box:hover {
  transform: translateY(-50px);
}

body .container .card .box:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 50%;
  height: 100%;
  background: rgba(255, 255, 255, 0.03);
}

body .container .card .box .content {
  padding: 20px;
  text-align: center;
}

body .container .card .box .content h2 {
  position: absolute;
  top: 40%;
  /* Center vertically */
  left: 40%;
  /* Center horizontally */
  transform: translate(-50%, -50%);
  /* Perfect centering */
  font-size: 8rem;
  /* Adjust size based on card size */
  color: rgba(255, 255, 255, 0.1);
  /* Set icon color */
  z-index: 0;
  /* Ensure it's behind other content */
}

body .container .card .box .content h3 {
  font-size: 1.8rem;
  color: #fff;
  z-index: 1;
  transition: 0.5s;
  margin-bottom: 15px;
}

body .container .card .box .content p {
  font-size: 1rem;
  font-weight: 300;
  color: rgba(255, 255, 255, 0.9);
  z-index: 1;
  transition: 0.5s;
}

body .container .card .box .content a {
  position: relative;
  display: inline-block;
  padding: 8px 20px;
  background: transparent;
  border: none;
  border-radius: 5px;
  text-decoration: none;
  color: white;
  margin-top: 200px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  transition: 0.5s;
  padding: 15px 30px;
  /* Adjust padding for button size */
  font-size: 1.5rem;
  /* Change this value to adjust text size */
}

body .container .card .box .content a:hover {
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.6);
  background: #fff;
  color: #000;
}

/* For custom icon colors in each card */
body .container .card:nth-child(1) .box .content h2 {
  color: #eb0028;
  /* Custom color for the first icon */
}

body .container .card:nth-child(2) .box .content h2 {
  color: #28a745;
  /* Custom color for the second icon */
}

body .container .card:nth-child(3) .box .content h2 {
  color: #007bff;
  /* Custom color for the third icon */
}

/* Hover effect for the card */
body .container .card:hover .box .content h2 {
  color: rgba(255, 255, 255, 0.9);
  /* Lighten the icon on hover */
}

.icon {
  font-size: 160%;
  /* Increase icon size */
  color: #eb0028;
  /* Default color for icons */
  display: block;
  margin: 0 auto;
}

p {
  color: #e0e0e0;
}

/*Home page card CSS ends here*/


/* Home page image */


.card:hover .box {
  transform: translateY(-30px);
  border-color: #e62b1e;
  box-shadow: 0 20px 40px rgba(230, 43, 30, 0.2);
}

.card .box .content {
  padding: 20px;
  text-align: center;
}

.card .box .content h2 {
  position: absolute;
  top: -10px;
  right: 30px;
  font-size: 8em;
  color: rgba(255, 255, 255, 0.05);
  transition: 0.5s;
}

.card:hover .box .content h2 {
  color: rgba(230, 43, 30, 0.05);
}

.card .box .content h3 {
  font-size: 1.8em;
  color: rgba(255, 255, 255, 0.9);
  z-index: 1;
  transition: 0.5s;
}

.card .box .content p {
  font-size: 16px;
  font-weight: 300;
  color: rgba(255, 255, 255, 0.7);
  z-index: 1;
  transition: 0.5s;
  margin: 20px 0;
}

.card .box .content a {
  position: relative;
  display: inline-block;
  padding: 8px 20px;
  background: #e62b1e;
  margin-top: 15px;
  border-radius: 20px;
  color: #fff;
  text-decoration: none;
  font-weight: 400;
  box-shadow: 0 10px 20px rgba(230, 43, 30, 0.2);
  transition: 0.5s;
}

.card .box .content a:hover {
  transform: translateY(-2px);
  box-shadow: 0 20px 40px rgba(230, 43, 30, 0.4);
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .container {
    padding: 30px 10px;
    gap: 30px;
  }

  .card {
    width: 100%;
    max-width: 300px;
  }

  .serendipity-logo h1 {
    font-size: 2.5rem;
  }
}

/* Animation keyframes for various effects */
@keyframes float {

  0%,
  100% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-10px);
  }
}

@keyframes glow {

  0%,
  100% {
    box-shadow: 0 0 20px rgba(230, 43, 30, 0.3);
  }

  50% {
    box-shadow: 0 0 40px rgba(230, 43, 30, 0.6);
  }
}

.hero-image-container {
  width: 100%;
  height: 90vh;
  position: relative;
  overflow: hidden;
}

.hero-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.hero-container {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.hero-video {
  width: 100%;
  height: auto;
  max-height: 100%;
  object-fit: cover;
}

@media (max-width: 768px) {
  .hero-image-container {
    height: 50vh;
    /* Reduce height for tablets and small devices */
  }

  .hero-container {
    flex-direction: column;
    justify-content: flex-start;
    /* Align content towards the top */
    height: auto;
    /* Allow height to adjust based on content */
    padding: 1rem;
    /* Add padding for better spacing */
  }

  .hero-video {
    height: auto;
    max-height: 60vh;
    /* Limit video height on smaller screens */
  }
}

@media (max-width: 480px) {
  .hero-image-container {
    height: 40vh;
    /* Smaller height for very small devices */
  }

  .hero-container {
    padding: 0.5rem;
    /* Smaller padding for mobile */
  }

  .hero-image {
    object-position: top;
    /* Adjust focus of the image */
  }
}

@media (min-width: 768px) and (max-width: 1366px) {
  .hero-image-container {
    height: 75vh;
    /* Balance height for larger tablets */
  }

  .hero-container {
    flex-direction: column;
    /* Ensure content remains stacked */
    justify-content: center;
    /* Center content vertically */
    height: auto;
  }

  .hero-image,
  .hero-video {
    object-fit: cover;
    object-position: center;
    /* Ensure proper focus for both image and video */
    width: 100%;
    max-height: 75vh;
    /* Avoid stretching beyond viewport */
  }
}

@media (orientation: portrait) {
  .hero-image-container {
    height: 70vh;
    /* Slightly shorter height for portrait mode */
  }

  .hero-container {
    padding: 2rem;
    /* Add some padding for better appearance */
  }
}

@media (orientation: landscape) {
  .hero-image-container {
    height: 85vh;
    /* Utilize more screen height in landscape mode */
  }
}

@media (min-resolution: 2dppx) {

  .hero-image,
  .hero-video {
    image-rendering: -webkit-optimize-contrast;
    /* Ensure high-quality rendering */
    height: 100%;
    width: 100%;
  }
}


/* Event Banner */
.event-banner {
  position: absolute;
  /* Position the banner on top of the image */
  bottom: 20px;
  /* Adjust placement as needed */
  left: 20px;
  /* Adjust placement as needed */
  z-index: 2;
  /* Layering order: Keep it above the image */
  background-color: rgba(0, 0, 0, 0.7);
  /* Semi-transparent background for readability */
  color: white;
  padding: 20px;
  border-radius: 8px;
  max-width: 400px;
  width: calc(100% - 40px);
  /* Ensure responsiveness */
  text-align: left;
}

.event-banner h2 {
  font-size: 24px;
  margin-bottom: 10px;
}

.event-banner p {
  font-size: 16px;
  margin-bottom: 10px;
}

.event-banner.banner-visible {
  opacity: 1;
  transform: translateY(0);
}

.event-banner.banner-hidden {
  opacity: 0;
  transform: translateY(100%);
}

.event-logo {
  flex: 0 0 300px;
  margin-right: 30px;
}

.serendipity-logo {
  width: 100%;
  height: auto;
}

.event-details {
  color: white;
  flex-grow: 1;
}

.event-details h2 {
  font-size: 32px;
  margin-bottom: 15px;
}

.event-details p {
  font-size: 18px;
  margin-bottom: 10px;
}

.event-description {
  font-size: 16px;
  line-height: 1.5;
  margin-bottom: 20px;
}

.buy-tickets-btn {
  background-color: #e62b1e;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 15px;
  font-weight: bold;
  text-transform: uppercase;
  text-decoration: none;
  display: inline-block;
}

/* Responsive Adjustments */
@media (max-width: 1024px) {
  .event-banner {
    position: relative;
    bottom: auto;
    left: auto;
    margin: 20px;
  }

  .event-logo {
    flex: 0 0 250px;
    margin-right: 20px;
  }

  .event-details h2 {
    font-size: 28px;
  }

  .event-details p {
    font-size: 16px;
  }
}

@media screen and (max-width: 480px) {
  .event-banner {
    padding: 15px;
  }

  .serendipity-logo {
    max-width: 85%;
  }

  .event-details h2 {
    font-size: 1.2rem;
  }

  .event-details p {
    font-size: 0.9rem;
  }

  .buy-tickets-btn {
    padding: 8px 15px;
    font-size: 0.8rem;
  }

  .event-banner h2 {
    font-size: 18px;
  }

  .event-banner p {
    font-size: 12px;
  }

}

.maps {
  width: 170px;
  height: 100vh;
  display: flex;
  margin: auto;
  display: flex;
  justify-content: center;
  /* Centers the iframe horizontally */
  align-items: center;
  /* Centers the iframe vertically (if needed) */

}

.ted-button {
  background-color: #eb0028;
  /* Initial background color */
  color: white;
  /* Initial text color */
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
  /* Smooth transition */
}

.ted-button:hover {
  background-color: white;
  /* Change background to white on hover */
  color: black;
  /* Change text color to black on hover */
}

/* Base styles for the sponsor wheel container */
.sponsor-wheel-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2rem;
  min-height: 90vh;
  align-items: center;
  width: 100%;
  max-width: 1400px;
  margin: 0 auto;
  padding: 20px;
}

.wheel-container {
  width: 100%;
  padding-bottom: 100%;
  position: relative;
  max-width: 600px;
  margin: 0 auto;
}

.wheel-svg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.description-container {
  padding: 2rem;
}

/* Text styles for the wheel */
.wheel-center-text {
  font-size: 16px;
}

.section-text {
  font-size: 14px;
}

/* Media query for tablets */
@media screen and (max-width: 1024px) {
  .sponsor-wheel-container {
    grid-template-columns: 1fr;
    gap: 1rem;
  }

  .wheel-container {
    max-width: 500px;
    padding-bottom: 500px;
  }

  .description-container {
    max-width: 500px;
    margin: 0 auto;
  }

  .wheel-center-text {
    font-size: 14px;
  }

  .section-text {
    font-size: 12px;
  }
}

/* Media query for mobile devices */
@media screen and (max-width: 768px) {
  .sponsor-wheel-container {
    min-height: auto;
    padding: 10px;
  }

  .wheel-container {
    max-width: 100%;
    padding-bottom: 100%;
  }

  .description-container {
    padding: 1rem;
  }

  .wheel-center-text {
    font-size: 12px;
  }

  .section-text {
    font-size: 10px;
  }
}

/* Media query for small mobile devices */
@media screen and (max-width: 480px) {
  .sponsor-wheel-container {
    gap: 0.5rem;
  }

  .description-container {
    padding: 0.5rem;
  }
}

/* Animation for description box */
.description-box {
  transition: all 0.3s ease;
  opacity: 0;
  transform: translateX(20px);
}

.description-box.visible {
  opacity: 1;
  transform: translateX(0);
}

/* Common styles for both sections */
.ted-content,
.tedx-content {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding: 1rem;
  max-width: 1200px;
  margin: 0 auto;
}

/* Common image container styles */
.ted-image-container,
.tedx-image-container {
  width: 100%;
  overflow: hidden;
  order: -1;
  /* This puts images on top in mobile view */
}

/* Common text container styles */
.ted-text,
.tedx-text {
  width: 100%;
}

.ted-text p,
.tedx-text p {
  line-height: 1.6;
  margin-bottom: 1.5rem;
}

/* Common image styles */
.ted-image {
  width: 100%;
  height: 300px;
  object-fit: cover;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease;
  display: block;
  max-width: 100%;
}

.ted-image:hover {
  box-shadow: 0 8px 12px rgba(0, 0, 0, 0.2);
}

/* Title styles */
.ted-title,
.tedx-title {
  text-align: center;
  margin: 2rem 0;
  font-size: 2rem;
  font-weight: bold;
}

/* Button styles */
.ted-button {
  background-color: #eb0028;
  color: white;
  padding: 0.8rem 1.5rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.ted-button:hover {
  background-color: #cc0024;
}

/* Media query for tablets */
@media screen and (min-width: 768px) {

  .ted-content,
  .tedx-content {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 3rem;
    padding: 2rem;
  }

  .ted-image-container,
  .tedx-image-container {
    width: 1000;
    order: 0;
    /* Reset order for desktop view */
  }

  /* Special handling for first section (TED) */
  .ted-content .ted-image-container {
    order: 2;
    /* Put image on right for first section */
  }

  .ted-text,
  .tedx-text {
    width: 48%;
  }

  .ted-image {
    height: 400px;
    width: 500px;
  }

  .ted-title,
  .tedx-title {
    font-size: 2.5rem;
  }
}

/* Media query for desktop */
@media screen and (min-width: 1024px) {

  .ted-content,
  .tedx-content {
    padding: 3rem 2rem;
  }

  .ted-image {
    height: 500px;
  }

  .ted-title,
  .tedx-title {
    font-size: 3rem;
  }
}

/* Media query for smaller mobile devices */
@media screen and (max-width: 480px) {

  .ted-content,
  .tedx-content {
    padding: 1rem;
    gap: 1.5rem;
  }

  .ted-image {
    height: 250px;
  }

  .ted-title,
  .tedx-title {
    font-size: 1.75rem;
    margin: 1.5rem 0;
  }
}

\\pass .buy-tickets-section {
  display: flex;
  gap: 20px;
  margin: 20px;
}

.student-pass-card {
  width: 90%;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #fff;
  font-family: Arial, sans-serif;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.title {
  font-size: 20px;
  font-weight: bold;
  color: #333;

  margin-bottom: 10px;
}

.price {
  font-size: 24px;
  color: #e63946;

  margin: 0;
}

.price-note {
  font-size: 14px;
  color: #666;
  margin-bottom: 20px;
}

.features {
  list-style: none;
  padding: 0;
  margin: 0;
}

.features li {
  font-size: 14px;
  margin: 8px 0;
}

.included {
  color: #2a9d8f;
}

.not-included {
  color: #e76f51;
}

\\fallback-image-css .fallback-image-container {
  position: relative;
  /* Enable positioning for the banner */
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  /* Full screen height */
  width: 100vw;
  /* Full screen width */
  margin: 0;
  padding: 0;
  overflow: hidden;
  /* Prevent scrollbars */
}

.fallback-image {
  position: absolute;
  /* Ensure the image stays behind the banner */
  top: 0;
  left: 0;
  width: 100%;
  /* Cover the width of the screen */
  height: 90%;
  /* Cover the height of the screen */
  object-fit: cover;
  /* Ensures the image fills the screen without distortion */
  z-index: 1;
  /* Layering order: Keep it behind the banner */
}


/* Responsive Adjustments */
@media (max-width: 1200px) {
  .fallback-image {
    max-width: 600px;
    /* Adjust for smaller laptop screens */
  }
}

@media (max-width: 768px) {
  .fallback-image {
    max-width: 80%;
    /* Adjust for tablets */
  }
}

@media (max-width: 480px) {
  .fallback-image {
    max-width: 100%;
    /* Adjust for mobile */
  }
}


/* Speaker*/

/* General Grid Styling */
.speakers-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
  padding: 40px;
}
@media (max-width: 1024px) { /* Laptops */
  .speakers-grid {
    grid-template-columns: repeat(3, 1fr); /* 3 columns */
    gap: 15px;
    padding: 30px;
  }

  .modal-content {
    width: 80%; /* Reduce modal width */
  }
}

@media (max-width: 768px) { /* Tablets */
  .speakers-grid {
    grid-template-columns: repeat(2, 1fr); /* 2 columns */
    gap: 15px;
    padding: 20px;
  }

  .modal-content {
    width: 90%; /* Further reduce modal width */
  }

  .modal-image {
    max-height: 200px; /* Adjust image height for smaller screens */
  }
}

@media (max-width: 480px) { /* Mobile */
  .speakers-grid {
    grid-template-columns: 1fr; /* 1 column */
    gap: 10px;
    padding: 15px;
  }

  .speaker-name {
    font-size: 14px; /* Reduce font size */
    padding: 6px 0;
  }

  .modal-content {
    width: 95%; /* Maximize modal width for mobile */
    padding: 15px; /* Adjust padding */
  }

  .modal-image {
    max-height: 150px; /* Further reduce image height */
  }

  .close-button {
    font-size: 20px; /* Smaller close button */
  }
}

/* Ensure Modal Header and Text Stay Scaled */
.modal-content h2 {
  font-size: 1.5rem; /* Scales for larger headers */
}

@media (max-width: 768px) {
  .modal-content h2 {
    font-size: 1.25rem; /* Adjust for tablets */
  }
}

@media (max-width: 480px) {
  .modal-content h2 {
    font-size: 1rem; /* Adjust for mobile */
  }
}

/* Speaker Card Styling */
.speaker-card {
  position: relative;
  overflow: hidden;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s;
}

.speaker-image {
  width: 100%;
  height: auto;
  display: block;
  border-radius: 8px;
  transition: transform 0.3s;
}

/* Add darker red background overlay on hover */
.speaker-card::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(55, 0, 0, 0.85); /* Darker red */
  opacity: 0;
  transition: opacity 0.3s;
  z-index: 1;
}

/* Speaker Name Styling */
.speaker-name {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.6);
  color: #fff;
  text-align: center;
  font-size: 16px;
  padding: 8px 0;
  z-index: 2;
}

/* Icon Styling */
.speaker-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  pointer-events: auto; /* Make the icon clickable */
  transition: opacity 0.3s;
  z-index: 3;
  cursor: pointer; /* Change cursor to pointer */
}

.speaker-icon svg {
  width: 40px;
  height: 40px;
  fill: white;
}

/* Hover Effects */
.speaker-card:hover {
  transform: translateY(-5px);
}

.speaker-card:hover .speaker-icon {
  opacity: 1;
}

.speaker-card:hover::after {
  opacity: 1;
}


/* Modal Overlay */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.75);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}
/* Modal Content */
.modal-content {
  background: #141414; /* Background same as overlay */
  border-radius: 8px;
  width: 500px;
  max-width: 90%;
  padding: 20px;
  position: relative;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  color: #fff; /* Set white text color for all text */
}

/* Modal Header */
.modal-content h2 {
  color: #fff; /* Ensure h2 is white */
  margin-bottom: 10px;
}


/* Modal Close Button */
.close-button {
  position: absolute;
  top: 10px;
  right: 15px;
  font-size: 24px;
  color: #333;
  cursor: pointer;
}

.close-button:hover {
  color: red;
}

/* Modal Image */
.modal-image {
  width: 100%;
  max-height: 300px;
  object-fit: contain;
  margin-bottom: 15px;
}
